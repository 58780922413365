<template>
	<div class="row-space-tbf" v-if="loaded">
		<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$router.push({name: 'users'})"><icon-arrow-back /></div></div>
		<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title">{{user.id ? $t('create-user.edit-user') :  $t('create-user.create-new-user') }}</h1>

				<div class="action">
					<v-popover class="help" :disabled="$resize && $mq.below(780)" offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf btn-animated" :delay="{show: 0, hide: 0}">
						<a class="btn-tbf blue only-icon " :href="helpData.link" target="_blank" v-if="helpData">
							<div class="icon"><icon-question /></div>
						</a>

						<template slot="popover">
							<div class="simple-text">{{ $t(`help_links.${helpData.type}`) }}</div>
						</template>
					</v-popover>

					<button class="btn-tbf white only-icon" @click="$router.push({name: 'users'})"><icon-close class="icon-close" /></button>
				</div>
			</div>

			<div class="alert-tbf blue alert-vacant-jobs" v-if="optionsVacantJobs.length && !user.id">
				<div class="data">
					<div class="title">{{ $tc('vacant_jobs.alert_create_user_title', optionsVacantJobs.length) }}</div>
					<div class="description">{{ $t('vacant_jobs.alert_create_user_description', { items: optionsVacantJobs.map(el => { return el.name }).join(", ") }) }}</div>
				</div>
				<div class="actions">
					<div class="input-box select-vacant-jobs">
						<div class="icon-left"><icon-vacant-job /></div>
						<multiselect 
						v-model="selectedVacantJob"
						:options="optionsVacantJobs"
						:allow-empty="true"
						:show-labels="false"
						:multiple="false" 
						:close-on-select="true"
						@input="populateDataByVacantJob"
						>
							<template slot="placeholder" slot-scope="props">
								<span class="text">{{$t('vacant_jobs.choose_option')}}</span>
							</template>
							<template slot="option" slot-scope="props">{{ props.option.name }}</template>
							<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
							<template slot="noResult">{{ $t('general.no_result') }}</template>
							<template slot="noOptions">{{ $t('general.empty_list') }}</template>
						</multiselect>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('create-user.user-details') }}</div>
					<div class="description">{{ $t('create-user.description-user-details') }}</div>

					<div class="input-group profile">
						<div class="upload-img update" onclick="document.getElementById('avatarFile').click();">
							<img v-if="urlAvatar != ''" :src="urlAvatar" class="img-circle"/>
							<img v-else src="../../../assets/user-circle.svg" class="img-circle"/>
							<div class="text">
								<p class="title">{{$t('profile.profile_picture')}}</p>
								<div class="with-image"><icon-upload /><p>{{$t('profile.upload_new_picture')}}</p></div>
							</div>
							<input style="display:none" id="avatarFile" type="file" accept="image/png, image/jpg, image/jpeg, image/svg" v-on:change="onAvatarChange">
						</div>
					</div>
				</div>
				<div class="form">
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.first-name') }}*</label>
							<div v-if="$v.user.first_name.$error" class="error-msg">{{ $t('validator.required') }}</div>
							<div v-if="errorsBe.first_name" class="error-msg">{{ errorsBe.first_name.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.user.first_name.$error}">
							<div class="icon-left"><icon-people /></div>
							<div class="icon-right" v-if="user.first_name != ''" @click.stop="user.first_name = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('create-user.first-name-ph')" class="input-text" v-model="user.first_name">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.last-name') }}*</label>	
							<div v-if="$v.user.last_name.$error" class="error-msg">{{ $t('validator.required') }}</div>
							<div v-if="errorsBe.last_name" class="error-msg">{{ errorsBe.last_name.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.user.last_name.$error}">
							<div class="icon-left"><icon-people /></div>
							<div class="icon-right" v-if="user.last_name != ''" @click.stop="user.last_name = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('create-user.last-name-ph')" class="input-text" v-model="user.last_name">
						</div>
					</div>

					<div class="input-group w-50" v-if="canChangeDummy">
						<div class="label-header">
							<label class="label">{{ $t('create-user.dummy_question') }}</label>
						</div>
						<div class="input-box bor-0">
							<div class="checkbox-list">
								<div class="checkbox-item m-4" @click="user.dummy_account = 1, user.email = ''" v-bind:class="{active: user.dummy_account === 1}">
									<div class="name">{{ $t('create-user.no') }}</div>
								</div>
								<div class="checkbox-item m-4" @click="user.dummy_account = 0, user.email = ''" v-bind:class="{active: user.dummy_account === 0}">
									<div class="name">{{ $t('create-user.yes') }}</div>
								</div>
							</div>
						</div>
					</div>

					<div class="input-group" :class="[$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50', {disabled: user.dummy_account}]">
						<div class="label-header">
							<label class="label">{{ $t('create-user.email') }}*</label>
							<div v-if="$v.user.email.$error" class="error-msg">{{ $t('validator.required') }}</div>
							<div v-if="errorsBe.email" class="error-msg">{{ errorsBe.email.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.user.email.$error || error_email}">
							<div class="icon-left"><icon-email /></div>
							<div class="icon-right" v-if="user.email != ''" @click.stop="user.email = ''"><icon-close class="icon-clear" /></div>
							<input type="email" :placeholder="$t('create-user.email-ph')" class="input-text" v-model="user.email" @change="error_email = false" :disabled="user.dummy_account == 1">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.phone') }}</label>
							<div v-if="errorsBe.phone" class="error-msg">{{ errorsBe.phone.join(" | ") }}</div>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-mobile /></div>
							<div class="icon-right" v-if="user.phone != ''" @click.stop="user.phone = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('create-user.phone-ph')" class="input-text" v-model="user.phone">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.date-of-employment') }}</label>
							<div v-if="errorsBe.date_of_employment" class="error-msg">{{ errorsBe.date_of_employment.join(" | ") }}</div>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right" v-if="Object.keys(user.date_of_employment).length" @click.stop="user.date_of_employment = {}"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfEmployment"
								v-model="user.date_of_employment"
								class="calendar-tbf"
								:configs="calendarConfigs"
							>
							<template v-slot:datePickerInput="props">
								<input
								class="vfc-single-input custom-input-picker"
								type="text"
								:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
								v-if="Object.keys(user.date_of_employment).length"/>

								<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('create-user.choose-date-of-employment') }}</div>
							</template>
							</FunctionalCalendar>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.vacations-days') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-people /></div>
							<div class="icon-right" v-if="user.vacation_days != ''" @click.stop="user.vacation_days = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('create-user.vacations-days-ph')" class="input-text" v-model="user.vacation_days">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && !$mq.above(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{$t('profile.rights')}}*</label>
							<div v-if="$v.selectedRight.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.selectedRight.$error}">
							<div class="icon-left"><icon-gears /></div>
							<multiselect 
							v-model="selectedRight"
							:options="optionsRights"
							:allow-empty="false"
							:show-labels="false"
							:multiple="false" 
							:close-on-select="true"
							:disabled="user.right == 'master_admin' ? true : false"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">{{$t('profile.choose_rights')}}</span>
								</template>
								<template slot="option" slot-scope="props">{{ $t(`profile.rights_name.${props.option}`) }}</template>
								<template slot="singleLabel" slot-scope="{ option }">{{ $t(`profile.rights_name.${option}`) }}</template>
								<template slot="noResult">{{ $t('general.no_result') }}</template>
								<template slot="noOptions">{{ $t('general.empty_list') }}</template>
							</multiselect>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('create-user.personal-details') }}</div>
					<div class="description">{{ $t('create-user.description-personal-details') }}</div>
				</div>
				<div class="form">
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.personal-email') }}</label>
						</div>
						<div class="input-box" v-bind:class="{has_error: error_business_email}">
							<div class="icon-left"><icon-email /></div>
							<div class="icon-right" v-if="business.email != ''" @click.stop="business.email = ''"><icon-close class="icon-clear" /></div>
							<input type="email" :placeholder="$t('create-user.email-ph')" class="input-text" v-model="business.email" @change="error_business_email = false">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.personal-phone') }}</label>
							<div v-if="errorsBe.business_phone" class="error-msg">{{ errorsBe.business_phone.join(" | ") }}</div>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-mobile /></div>
							<div class="icon-right" v-if="business.phone != ''" @click.stop="business.phone = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('create-user.phone-ph')" class="input-text" v-model="business.phone">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.date-of-birth') }}</label>
							<div v-if="errorsBe.date_of_birth" class="error-msg">{{ errorsBe.date_of_birth.join(" | ") }}</div>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-calendar /></div>
							<div class="icon-right" v-if="Object.keys(user.date_of_birth).length" @click.stop="user.date_of_birth = {}"><icon-close class="icon-clear" /></div>
							<FunctionalCalendar
								ref="CalendarDateOfBirth"
								v-model="user.date_of_birth"
								class="calendar-tbf"
								:configs="calendarConfigs"
							>
								<template v-slot:datePickerInput="props">
									<input
									class="vfc-single-input custom-input-picker"
									type="text"
									:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
									v-if="Object.keys(user.date_of_birth).length"/>

									<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('create-user.choose-date-of-birth') }}</div>
								</template>
							</FunctionalCalendar>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && !$mq.above(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{$t('show_user.timezone')}}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-globe /></div>
							<multiselect 
							v-model="selectedTimezone"
							:options="optionsTimezones"
							:allow-empty="false"
							:show-labels="false"
							:multiple="false" 
							:close-on-select="true"
							track-by="value" 
							label="name"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">{{$t('show_user.timezone')}}</span>
								</template>
								<template slot="noResult">{{ $t('general.no_result') }}</template>
								<template slot="noOptions">{{ $t('general.empty_list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group w-50">
						<div class="label-header">
							<label class="label">{{ $t('create-user.married') }}</label>
						</div>
						<div class="input-box bor-0">
							<div class="checkbox-list">
								<div class="checkbox-item m-4" @click="user.married = 0" v-bind:class="{active: user.married === 0}">
									<div class="name">{{ $t('create-user.no') }}</div>
								</div>
								<div class="checkbox-item m-4" @click="user.married = 1" v-bind:class="{active: user.married === 1}">
									<div class="name">{{ $t('create-user.yes') }}</div>
								</div>
							</div>
						</div>
					</div>
					<div class="input-group w-50">
						<div class="label-header">
							<label class="label">{{ $t('create-user.childrens') }}</label>
						</div>
						<div class="input-box bor-0">
							<div class="checkbox-list">
								<div class="checkbox-item m-4" @click="user.has_children = 0" v-bind:class="{active: user.has_children === 0}">
									<div class="name">{{ $t('create-user.no') }}</div>
								</div>
								<div class="checkbox-item m-4" @click="user.has_children = 1" v-bind:class="{active: user.has_children === 1}">
									<div class="name">{{ $t('create-user.yes') }}</div>
								</div>
							</div>
						</div>
					</div>

					<div class="delimeter-form"></div>
					
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.facebook_link') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-facebook /></div>
							<div class="icon-right" v-if="social_links.facebook_link != ''" @click.stop="social_links.facebook_link = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('create-user.facebook_link-ph')" class="input-text" v-model="social_links.facebook_link">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.linkedin_link') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-linkedin /></div>
							<div class="icon-right" v-if="social_links.linkedin_link != ''" @click.stop="social_links.linkedin_link = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('create-user.linkedin_link-ph')" class="input-text" v-model="social_links.linkedin_link">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.instagram_link') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-instagram /></div>
							<div class="icon-right" v-if="social_links.instagram_link != ''" @click.stop="social_links.instagram_link = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('create-user.instagram_link-ph')" class="input-text" v-model="social_links.instagram_link">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.twitter_link') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-twitter /></div>
							<div class="icon-right" v-if="social_links.twitter_link != ''" @click.stop="social_links.twitter_link = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('create-user.twitter_link-ph')" class="input-text" v-model="social_links.twitter_link">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.tiktok_link') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-tiktok /></div>
							<div class="icon-right" v-if="social_links.tiktok_link != ''" @click.stop="social_links.tiktok_link = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('create-user.tiktok_link-ph')" class="input-text" v-model="social_links.tiktok_link">
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.youtube_link') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-youtube /></div>
							<div class="icon-right" v-if="social_links.youtube_link != ''" @click.stop="social_links.youtube_link = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('create-user.youtube_link-ph')" class="input-text" v-model="social_links.youtube_link">
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('create-user.job-data') }}</div>
					<div class="description">{{ $t('create-user.description-job-data') }}</div>
				</div>
				<div class="form">
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.role') }}</label>
							<div class="create-new" @click="showModal('role', {from: 'create-user'})">{{ $t('create-user.add-role') }}</div>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-role /></div>
							<div class="icon-right" v-if="selectedRoles.length" @click.stop="selectedRoles = []"><icon-close class="icon-clear" /></div>
							<multiselect 
							class="hide-tags multiselect_checkbox"
							v-bind:class="{populate: selectedRoles.length}"
							v-model="selectedRoles"
							:options="optionsRoles"
							:allow-empty="true"
							:show-labels="false"
							:multiple="true" 
							:close-on-select="false"
							track-by="id" 
							label="name"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('create-user.choose-role') }}
									</span>
								</template>
								<template slot="selection" slot-scope="{ values, search, isOpen }">
									<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
									<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
								</template>
								<template slot="option" slot-scope="props">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
									</div>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.department') }}</label>
							<div class="create-new" @click="showModal('department', {from: 'create-user'})">{{ $t('create-user.add-department') }}</div>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-department /></div>
							<div class="icon-right" v-if="selectedDepartments.length" @click.stop="selectedDepartments = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
							class="hide-tags multiselect_checkbox"
							v-bind:class="{populate: selectedDepartments.length}"
							v-model="selectedDepartments"
							:options="optionsDepartments"
							:allow-empty="true"
							:show-labels="false"
							:multiple="true" 
							:close-on-select="false"
							track-by="id" 
							label="name"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('create-user.choose-department') }}
									</span>
								</template>
								<template slot="selection" slot-scope="{ values, search, isOpen }">
									<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
									<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
								</template>
								<template slot="option" slot-scope="props">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
									</div>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.workstation') }}</label>
							<div class="create-new" @click="showModal('workstation', {from: 'create-user'})">{{ $t('create-user.add-workstation') }}</div>
							
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-building /></div>
							<div class="icon-right" v-if="selectedWorkstation != ''" @click.stop="selectedWorkstation = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
							v-model="selectedWorkstation"
							v-bind:class="{populate: selectedWorkstation != ''}"
							:options="optionsWorkstations"
							:allow-empty="true"
							:show-labels="false"
							track-by="id"
							label="name"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('create-user.choose-workstation') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.work-schedule') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-work-schedule /></div>
							<div class="icon-right" v-if="selectedWorkSchedule != ''" @click.stop="selectedWorkSchedule = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								class="select-tags-tbf"
								v-bind:class="{populate: selectedWorkSchedule != ''}"
								v-model="selectedWorkSchedule"
								:options="optionsWorkSchedules"
								:allow-empty="true"
								:show-labels="false"
								track-by="id"
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('create-user.choose-template') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>

					<div class="info-form" v-if="selectedWorkSchedule != ''">
						<div class="info-work-schedule">
							<div class="title-template">{{ selectedWorkSchedule.name }}</div>
							<div class="intervals" v-if="selectedWorkSchedule.name != 'Custom'">
								<div class="interval">
									<div class="box">
										<div class="day">{{ $t('general.monday') }}</div>
										<div class="value" v-if="selectedWorkSchedule.schedules.find(el => el.day == 'monday')">{{ selectedWorkSchedule.schedules.find(el => el.day == 'monday').start_time + ' - ' + selectedWorkSchedule.schedules.find(el => el.day == 'monday').end_time }}</div>
										<div class="value" v-else>{{ $t('general.free') }}</div>
									</div>
								</div>
								<div class="interval">
									<div class="box">
										<div class="day">{{ $t('general.tuesday') }}</div>
										<div class="value" v-if="selectedWorkSchedule.schedules.find(el => el.day == 'tuesday')">{{ selectedWorkSchedule.schedules.find(el => el.day == 'tuesday').start_time + ' - ' + selectedWorkSchedule.schedules.find(el => el.day == 'tuesday').end_time }}</div>
										<div class="value" v-else>{{ $t('general.free') }}</div>
									</div>
								</div>
								<div class="interval">
									<div class="box">
										<div class="day">{{ $t('general.wednesday') }}</div>
										<div class="value" v-if="selectedWorkSchedule.schedules.find(el => el.day == 'wednesday')">{{ selectedWorkSchedule.schedules.find(el => el.day == 'wednesday').start_time + ' - ' + selectedWorkSchedule.schedules.find(el => el.day == 'wednesday').end_time }}</div>
										<div class="value" v-else>{{ $t('general.free') }}</div>
									</div>
								</div>
								<div class="interval">
									<div class="box">
										<div class="day">{{ $t('general.thursday') }}</div>
										<div class="value" v-if="selectedWorkSchedule.schedules.find(el => el.day == 'thursday')">{{ selectedWorkSchedule.schedules.find(el => el.day == 'thursday').start_time + ' - ' + selectedWorkSchedule.schedules.find(el => el.day == 'thursday').end_time }}</div>
										<div class="value" v-else>{{ $t('general.free') }}</div>
									</div>
								</div>
								<div class="interval">
									<div class="box">
										<div class="day">{{ $t('general.friday') }}</div>
										<div class="value" v-if="selectedWorkSchedule.schedules.find(el => el.day == 'friday')">{{ selectedWorkSchedule.schedules.find(el => el.day == 'friday').start_time + ' - ' + selectedWorkSchedule.schedules.find(el => el.day == 'friday').end_time }}</div>
										<div class="value" v-else>{{ $t('general.free') }}</div>
									</div>
								</div>
								<div class="interval">
									<div class="box">
										<div class="day">{{ $t('general.saturday') }}</div>
										<div class="value" v-if="selectedWorkSchedule.schedules.find(el => el.day == 'saturday')">{{ selectedWorkSchedule.schedules.find(el => el.day == 'saturday').start_time + ' - ' + selectedWorkSchedule.schedules.find(el => el.day == 'saturday').end_time }}</div>
										<div class="value" v-else>{{ $t('general.free') }}</div>
									</div>
								</div>
								<div class="interval">
									<div class="box">
										<div class="day">{{ $t('general.sunday') }}</div>
										<div class="value" v-if="selectedWorkSchedule.schedules.find(el => el.day == 'sunday')">{{ selectedWorkSchedule.schedules.find(el => el.day == 'sunday').start_time + ' - ' + selectedWorkSchedule.schedules.find(el => el.day == 'sunday').end_time }}</div>
										<div class="value" v-else>{{ $t('general.free') }}</div>
									</div>
								</div>
							</div>

							<div class="intervals" v-else>
								<div class="interval" v-bind:class="{has_error: $v.customSchedule.monday.start.$error || $v.customSchedule.monday.end.$error}">
									<div class="box">
										<div class="day">{{ $t('general.monday') }}</div>
										<div class="interval-inputs">
											<div class="start-hour" v-bind:class="{has_error: $v.customSchedule.monday.start.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.start')" :minute-interval="15" v-model="customSchedule.monday.start" :key="timepickerKey"></vue-timepicker>
											</div>
											<div v-bind:class="{has_error: $v.customSchedule.monday.end.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.end')" :minute-interval="15" v-model="customSchedule.monday.end" :key="timepickerKey"></vue-timepicker>
											</div>
										</div>
									</div>
								</div>
								<div class="interval" v-bind:class="{has_error: $v.customSchedule.tuesday.start.$error || $v.customSchedule.tuesday.end.$error}">
									<div class="box">
										<div class="day">{{ $t('general.tuesday') }}</div>
										<div class="interval-inputs">
											<div class="start-hour" v-bind:class="{has_error: $v.customSchedule.tuesday.start.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.start')" :minute-interval="15" v-model="customSchedule.tuesday.start" :key="timepickerKey"></vue-timepicker>
											</div>
											<div v-bind:class="{has_error: $v.customSchedule.tuesday.end.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.end')" :minute-interval="15" v-model="customSchedule.tuesday.end" :key="timepickerKey"></vue-timepicker>
											</div>
										</div>
									</div>
								</div>
								<div class="interval" v-bind:class="{has_error: $v.customSchedule.wednesday.start.$error || $v.customSchedule.wednesday.end.$error}">
									<div class="box">
										<div class="day">{{ $t('general.wednesday') }}</div>
										<div class="interval-inputs">
											<div class="start-hour" v-bind:class="{has_error: $v.customSchedule.wednesday.start.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.start')" :minute-interval="15" v-model="customSchedule.wednesday.start" :key="timepickerKey"></vue-timepicker>
											</div>
											<div v-bind:class="{has_error: $v.customSchedule.wednesday.end.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.end')" :minute-interval="15" v-model="customSchedule.wednesday.end" :key="timepickerKey"></vue-timepicker>
											</div>
										</div>
									</div>
								</div>
								<div class="interval" v-bind:class="{has_error: $v.customSchedule.thursday.start.$error || $v.customSchedule.thursday.end.$error}">
									<div class="box">
										<div class="day">{{ $t('general.thursday') }}</div>
										<div class="interval-inputs">
											<div class="start-hour" v-bind:class="{has_error: $v.customSchedule.thursday.start.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.start')" :minute-interval="15" v-model="customSchedule.thursday.start" :key="timepickerKey"></vue-timepicker>
											</div>
											<div v-bind:class="{has_error: $v.customSchedule.thursday.end.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.end')" :minute-interval="15" v-model="customSchedule.thursday.end" :key="timepickerKey"></vue-timepicker>
											</div>
										</div>
									</div>
								</div>
								<div class="interval" v-bind:class="{has_error: $v.customSchedule.friday.start.$error || $v.customSchedule.friday.end.$error}">
									<div class="box">
										<div class="day">{{ $t('general.friday') }}</div>
										<div class="interval-inputs">
											<div class="start-hour" v-bind:class="{has_error: $v.customSchedule.friday.start.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.start')" :minute-interval="15" v-model="customSchedule.friday.start" :key="timepickerKey"></vue-timepicker>
											</div>
											<div v-bind:class="{has_error: $v.customSchedule.friday.end.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.end')" :minute-interval="15" v-model="customSchedule.friday.end" :key="timepickerKey"></vue-timepicker>
											</div>
										</div>
									</div>
								</div>
								<div class="interval" v-bind:class="{has_error: $v.customSchedule.saturday.start.$error || $v.customSchedule.saturday.end.$error}">
									<div class="box">
										<div class="day">{{ $t('general.saturday') }}</div>
										<div class="interval-inputs">
											<div class="start-hour" v-bind:class="{has_error: $v.customSchedule.saturday.start.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.start')" :minute-interval="15" v-model="customSchedule.saturday.start" :key="timepickerKey"></vue-timepicker>
											</div>
											<div v-bind:class="{has_error: $v.customSchedule.saturday.end.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.end')" :minute-interval="15" v-model="customSchedule.saturday.end" :key="timepickerKey"></vue-timepicker>
											</div>
										</div>
									</div>
								</div>
								<div class="interval" v-bind:class="{has_error: $v.customSchedule.sunday.start.$error || $v.customSchedule.sunday.end.$error}">
									<div class="box">
										<div class="day">{{ $t('general.sunday') }}</div>
										<div class="interval-inputs">
											<div class="start-hour" v-bind:class="{has_error: $v.customSchedule.sunday.start.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.start')" :minute-interval="15" v-model="customSchedule.sunday.start" :key="timepickerKey"></vue-timepicker>
											</div>
											<div v-bind:class="{has_error: $v.customSchedule.sunday.end.$error}">
												<vue-timepicker close-on-complete auto-scroll advanced-keyboard hide-disabled-hours format="HH:mm" :placeholder="$t('general.end')" :minute-interval="15" v-model="customSchedule.sunday.end" :key="timepickerKey"></vue-timepicker>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('create-user.languages') }}</div>
					<div class="description">{{ $t('create-user.languages-description') }}</div>
				</div>
				<div class="form">
					<div class="saved-languages" v-if="savedLanguages.length">
						<div class="box">
							<div class="group-language" v-for="languageItem in savedLanguages">
								<div class="main-label">
									<div class="name">{{ languageItem.name }}</div>
									<div class="actions">
										<button class="btn-tbf white" @click="editLanguageLevel(languageItem)"><icon-edit /></button>
										<button class="btn-tbf white" @click="deleteLanguageLevel(languageItem)"><icon-trash /></button>
									</div>
								</div>
								<div class="levels-values">
									<div class="item-level">
										<div class="label">{{ $t('create-user.listening') }}</div>
										<div class="value">{{ languageItem.levels.find(el => el.type == 'listening').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'listening').level }}</div>
									</div>
									<div class="item-level">
										<div class="label">{{ $t('create-user.reading') }}</div>
										<div class="value">{{ languageItem.levels.find(el => el.type == 'reading').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'reading').level }}</div>
									</div>
									<div class="item-level">
										<div class="label">{{ $t('create-user.spoke-interaction') }}</div>
										<div class="value">{{ languageItem.levels.find(el => el.type == 'spoke_interaction').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'spoke_interaction').level }}</div>
									</div>
									<div class="item-level">
										<div class="label">{{ $t('create-user.spoke-production') }}</div>
										<div class="value">{{ languageItem.levels.find(el => el.type == 'spoke_production').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'spoke_production').level }}</div>
									</div>
									<div class="item-level">
										<div class="label">{{ $t('create-user.writing') }}</div>
										<div class="value">{{ languageItem.levels.find(el => el.type == 'writing').level == 'undefined' ? $t('evaluation.not_completed') : languageItem.levels.find(el => el.type == 'writing').level }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.language') }}</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-globe /></div>
							<div class="icon-right" v-if="selectedLanguage != ''" @click.stop="selectedLanguage = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								v-model="selectedLanguage"
								class="select-tags-tbf"
								v-bind:class="{populate: selectedLanguage != ''}"
								:options="optionsLanguages"
								:allow-empty="true"
								:show-labels="false"
								track-by="id" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('create-user.choose-language') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>

					<div class="levels-language" v-if="selectedLanguage">
						<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
							<div class="label-header">
								<label class="label">{{ $t('create-user.listening') }}</label>
								
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-level /></div>
								<div class="icon-right" v-if="selectedLevelListening != ''" @click.stop="selectedLevelListening = ''"><icon-close class="icon-clear" /></div>
								<multiselect 
									v-model="selectedLevelListening"
									v-bind:class="{populate: selectedLevelListening != ''}"
									class="select-tags-tbf"
									:options="optionsListening"
									:allow-empty="false"
									:show-labels="false"
									track-by="value" 
									label="text"
									>
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{ $t('create-user.choose-level') }}
										</span>
									</template>
									<template slot="singleLabel" slot-scope="props">
										<div class="option_ellipsis">{{ props.option.value }}</div>
									</template>
									<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
								</multiselect>
							</div>
						</div>
						<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
							<div class="label-header">
								<label class="label">{{ $t('create-user.reading') }}</label>
								
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-level /></div>
								<div class="icon-right" v-if="selectedLevelReading != ''" @click.stop="selectedLevelReading = ''"><icon-close class="icon-clear" /></div>
								<multiselect 
									v-model="selectedLevelReading"
									v-bind:class="{populate: selectedLevelReading != ''}"
									class="select-tags-tbf"
									:options="optionsReading"
									:allow-empty="false"
									:show-labels="false"
									track-by="value" 
									label="text"
									>
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{ $t('create-user.choose-level') }}
										</span>
									</template>
									<template slot="singleLabel" slot-scope="props">
										<div class="option_ellipsis">{{ props.option.value }}</div>
									</template>
									<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
								</multiselect>
							</div>
						</div>
						<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
							<div class="label-header">
								<label class="label">{{ $t('create-user.spoke-interaction') }}</label>
								
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-level /></div>
								<div class="icon-right" v-if="selectedLevelSpInt != ''" @click.stop="selectedLevelSpInt = ''"><icon-close class="icon-clear" /></div>
								<multiselect 
									v-model="selectedLevelSpInt"
									class="select-tags-tbf"
									v-bind:class="{populate: selectedLevelSpInt != ''}"
									:options="optionsSpokenInteraction"
									:allow-empty="false"
									:show-labels="false"
									track-by="value" 
									label="text"
									>
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{ $t('create-user.choose-level') }}
										</span>
									</template>
									<template slot="singleLabel" slot-scope="props">
										<div class="option_ellipsis">{{ props.option.value }}</div>
									</template>
									<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
								</multiselect>
							</div>
						</div>
						<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
							<div class="label-header">
								<label class="label">{{ $t('create-user.spoke-production') }}</label>
								
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-level /></div>
								<div class="icon-right" v-if="selectedLevelSpPro != ''" @click.stop="selectedLevelSpPro = ''"><icon-close class="icon-clear" /></div>
								<multiselect 
									v-model="selectedLevelSpPro"
									class="select-tags-tbf"
									v-bind:class="{populate: selectedLevelSpPro != ''}"
									:options="optionsSpokenProduction"
									:allow-empty="false"
									:show-labels="false"
									track-by="value" 
									label="text"
									>
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{ $t('create-user.choose-level') }}
										</span>
									</template>
									<template slot="singleLabel" slot-scope="props">
										<div class="option_ellipsis">{{ props.option.value }}</div>
									</template>
									<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
								</multiselect>
							</div>
						</div>
						<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
							<div class="label-header">
								<label class="label">{{ $t('create-user.writing') }}</label>
							</div>
							<div class="input-box">
								<div class="icon-left"><icon-level /></div>
								<div class="icon-right" v-if="selectedLevelWriting != ''" @click.stop="selectedLevelWriting = ''"><icon-close class="icon-clear" /></div>
								<multiselect 
									v-model="selectedLevelWriting"
									class="select-tags-tbf"
									v-bind:class="{populate: selectedLevelWriting != ''}"
									:options="optionsWriting"
									:allow-empty="false"
									:show-labels="false"
									track-by="value" 
									label="text"
									>
									<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{ $t('create-user.choose-level') }}
										</span>
									</template>
									<template slot="singleLabel" slot-scope="props">
										<div class="option_ellipsis">{{ props.option.value }}</div>
									</template>
									<template slot="noResult">{{ $t('create-user.no-results') }}</template>
									<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
								</multiselect>
							</div>
						</div>
						<div class="input-group input-group-btn" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
							<div class="add-more-btn" :class="[{'not-padd': $resize && $mq.between([1071,1150]) || $resize && $mq.below(600)}]">
								<button class="btn-tbf blue" @click="addOptionLanguage">
									<span class="text">{{ $t('create-user.add-another-language') }}</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('create-user.skills') }}</div>
					<div class="description">{{ $t('create-user.description-skills') }}</div>
				</div>
				<div class="form">
					<div class="saved-skills" v-if="savedSkills.length">
						<div class="box">
							<div class="group-skill" v-for="skillItem in savedSkills">
								<div class="main-label">
									<div class="name">{{ skillItem.name }}</div>
									<div class="actions">
										<button class="btn-tbf white" @click="editSkillLevel(skillItem)"><icon-edit /></button>
										<button class="btn-tbf white" @click="deleteSkillLevel(skillItem)"><icon-trash /></button>
									</div>
								</div>
								<div class="level-skill">
									{{ skillItem.level ? $t(`skill.type.${skillItem.level}`) : $t('evaluation.not_completed') }}
								</div>
							</div>
						</div>
					</div>

					<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $resize && $mq.below(600) ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{ $t('create-user.skill') }}</label>
							<div class="create-new" @click="showModal('skill', {from: 'create-user'})">{{$t('create-user.add-skill')}}</div>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-skill /></div>
							<div class="icon-right" v-if="selectedSkill != ''" @click.stop="selectedSkill = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								v-model="selectedSkill"
								v-bind:class="{populate: selectedSkill != ''}"
								class="select-tags-tbf"
								:options="optionsSkills"
								:allow-empty="true"
								:show-labels="false"
								track-by="id" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('create-user.choose-skill') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>

					<div class="input-group" v-if="selectedSkill" :class="{'w-25': $resize && $mq.above(1330), 'w-50': $resize && $mq.between([600,1329]), 'w-100': $resize && $mq.below(600)}">
						<div class="label-header">
							<label class="label">{{ $t('create-user.level') }}</label>
							<div v-if="$v.selectedSkillLevel.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.selectedSkillLevel.$error}">
							<div class="icon-left"><icon-level /></div>
							<div class="icon-right" v-if="selectedSkillLevel != ''" @click.stop="selectedSkillLevel = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
								v-model="selectedSkillLevel"
								v-bind:class="{populate: selectedSkillLevel != ''}"
								class="select-tags-tbf"
								:options="optionsSkillsLevels"
								:allow-empty="false"
								:show-labels="false"
								track-by="key" 
								label="name"
								>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('create-user.choose-level') }}
									</span>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>

					<div class="input-group input-group-btn" :class="{'w-25': $resize && $mq.above(1330), 'w-50': $resize && $mq.between([600,1329]), 'w-100': $resize && $mq.below(600)}" v-if="selectedSkill">
						<div class="add-more-btn" :class="[{'not-padd': $resize && $mq.below(1070) || $mq.between([1151,1329])}]">
							<button class="btn-tbf blue" @click="addSkill">
								<span class="text">{{ $t('create-user.add-another-skill') }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{ $t('create-user.hobbies') }}</div>
					<div class="description">{{ $t('create-user.description-hobbies') }}</div>
				</div>
				<div class="form">
					<div class="input-group w-100">
						<div class="add-task">
							<div class="main-input">
								<multiselect class="multiselect-hobbies" 
								v-model="inputHobby" 
								:placeholder="$t('create-user.placeholder_hobby')" 
								:options="optionsHobbies" 
								:taggable="true"
								:tag-placeholder="$t('v-multiselect.add_new_hobby')" 
								:selectLabel="$t('v-multiselect.select')"
								:selectedLabel="$t('v-multiselect.selected')"
								:deselectLabel="$t('v-multiselect.deselect')"
								@tag="addNewHobby">
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
								</multiselect>
							</div>
							<div class="actions" v-if="inputHobby != ''">
								<button class="btn-tbf white" @click="inputHobby = ''"><span class="text">{{ $t('general.cancel') }}</span></button>
								<button class="btn-tbf blue" @click="addHobby"><span class="text">{{ $t('general.add') }}</span></button>
							</div>
						</div>
					</div>

					<div v-if="savedHobbies.length > 0" class="input-group w-100">
						<div class="textarea-selected-hobbies">
							<span v-for="hobby, index in savedHobbies" class="name">
								{{ hobby }}
								<button class="remove" @click="deleteHobby(index)"><icon-close /></button>
							</span>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group last">
				<div class="data-group">
					<div class="title">{{ $t('create-user.other-files') }}</div>
					<div class="description">{{ $t('create-user.description-other-files') }}</div>
				</div>
				<div class="form">
					<div class="input-group w-100">
						<div class="add-task add-file">
							<div class="header-input-file">
								<div class="main-input file-group">
									<button class="btn-tbf blue upload-btn" onclick="document.getElementById('otherFile').click();">
										<span class="text">{{ $t('create-user.add-file') }}</span>
									</button>
									<div class="file-name">{{ fileUpload ? fileUpload.name : '' }}</div>
									<input style="display:none" id="otherFile" type="file" v-on:change="onFileChange">
								</div>

								<div class="check-expire-file" v-if="fileUpload || updateFile !== false">
									<div class="checkboxes-group">
										<label class="checkbox-tbf no-margin">
											<span class="text-checkbox">{{ $t('create-user.file-expire') }}</span>
											<input type="checkbox" value="1" v-model="fileExpire">
											<span class="checkmark"></span>
										</label>						
									</div>
								</div>
							</div>

							<div class="inputs-group-expire" v-if="fileExpire">
								<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
									<div class="label-header">
										<label class="label">{{ $t('create-user.date-file-expire') }}</label>
										<div v-if="$v.fileDateExpire.$error" class="error-msg">{{ $t('validator.required') }}</div>
									</div>
									<div class="input-box">
										<div class="icon-left"><icon-calendar /></div>
										<div class="icon-right" v-if="Object.keys(fileDateExpire).length" @click.stop="fileDateExpire = {}"><icon-close class="icon-clear" /></div>
										<FunctionalCalendar
										ref="CalendarDateOfEmployment"
										v-model="fileDateExpire"
										class="calendar-tbf"
										:configs="{...calendarConfigs, disabledDates: ['beforeToday'], enabledDates: ['afterToday']}"
										>
											<template v-slot:datePickerInput="props">
												<input
												class="vfc-single-input custom-input-picker"
												type="text"
												:value="$options.filters.moment(props.selectedDate, 'DD MMM YYYY')" 
												v-if="Object.keys(fileDateExpire).length"/>

												<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('create-user.choose-date-file-expire') }}</div>
											</template>
										</FunctionalCalendar>
									</div>
								</div>

								<div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50'">
									<div class="label-header">
										<label class="label">{{ $t('create-user.file-users') }}</label>
										<div v-if="$v.fileExpireUsers.$error" class="error-msg">{{ $t('validator.required') }}</div>
									</div>
									<div class="input-box">
										<div class="icon-left"><icon-role /></div>
										<div class="icon-right" v-if="fileExpireUsers.length" @click.stop="fileExpireUsers = []"><icon-close class="icon-clear" /></div>
										<multiselect 
										class="hide-tags  multiselect_checkbox"
										v-bind:class="{populate: fileExpireUsers.length}"
										v-model="fileExpireUsers"
										:options="optionsUsers"
										:allow-empty="true"
										:show-labels="false"
										:multiple="true" 
										:close-on-select="false"
										track-by="id" 
										label="name"
										>
											<template slot="placeholder" slot-scope="props">
												<span class="text">
													{{ $t('create-user.choose-file-users') }}
												</span>
											</template>
											<template slot="selection" slot-scope="{ values, search, isOpen }">
												<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
												<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
											</template>
											<template slot="option" slot-scope="props">
												<div class="checkbox">
													<div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
												</div>
											</template>
											<template slot="noResult">{{ $t('create-user.no-results') }}</template>
											<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
										</multiselect>
									</div>
								</div>
							</div>

							<div class="actions" v-if="updateFile !== false">
								<button class="btn-tbf white" @click="cancelEdit"><span class="text">{{ $t('general.cancel') }}</span></button>
								<button class="btn-tbf blue" @click="updateFileFunction"><span class="text">{{ $t('general.update') }}</span></button>
							</div>
							<div class="actions" v-else-if="fileUpload">
								<button class="btn-tbf white" @click="clearFile"><span class="text">{{ $t('general.cancel') }}</span></button>
								<button class="btn-tbf blue" @click="addFile"><span class="text">{{ $t('create-user.upload') }}</span></button>
							</div>
						</div>

						<div v-if="fileError" class="error-msg custom-file-error">{{ fileError }}</div>
					</div>


					<div v-if="savedFiles.length > 0" class="input-group w-100">
						<div class="textarea-selected-files">
							<div v-for="file, index in savedFiles" class="file-item">
								<span class="name">
								 	<icon-file class="icon-file" /> {{ file.name }}
									<button class="edit" @click="editFile(file, index)"><icon-edit /></button>
									<button class="remove" @click="deleteFile(file, index)"><icon-close /></button>
								</span>
								<div class="expire-date" v-if="file.expire_date">
									<span>{{ $t('create-user.date-file-expire') }} {{ file.expire_date | moment('DD MMM YYYY') }}</span>
								</div>
								<div class="expire-date" v-else>
									<span>{{ $t('create-user.not-file-expire') }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="space-right"></div>
		<div class="form-submit crud">
			<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
			<button class="btn-tbf grey" @click="$router.push({name: 'users'})"><span class="text">{{ $t('general.cancel') }}</span></button>
			<button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')" v-if="!user.id">
				<div class="loader"></div>
				<span class="text">{{ $t('general.add') }}</span>
			</button>
			<button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')" v-else>
				<div class="loader"></div>
				<span class="text">{{ $t('general.update') }}</span>
			</button>
		</div>
	</div>
	<loader-users-create v-else/>
</template>

<script>
	import IconArrowBack from '../../Icons/ArrowLong'
	import IconClose from '../../Icons/Close'
	import IconPeople from '../../Icons/People'
	import IconEdit from '../../Icons/Edit'
	import IconTrash from '../../Icons/Trash'
	import IconUpload from '../../Icons/Upload'
	import IconBuilding from '../../Icons/Building'
	import IconEmail from '../../Icons/Email'
	import IconMobile from '../../Icons/Mobile'
	import IconCalendar from '../../Icons/Calendar'
	import IconRole from '../../Icons/Role'
	import IconDepartment from '../../Icons/Department'
	import IconWorkSchedule from '../../Icons/WorkSchedule'
	import IconGlobe from '../../Icons/Globe'
	import IconSkill from '../../Icons/Skill'
	import IconLevel from '../../Icons/Level'
	import IconFile from '../../Icons/FilePdf'
	import IconGears from '../../Icons/Gears'
	import IconVacantJob from '../../Icons/VacantJob'
	import IconQuestion from '../../Icons/Question'
	
	import IconFacebook from '../../Icons/Facebook'
	import IconLinkedin from '../../Icons/Linkedin'
	import IconInstagram from '../../Icons/Instagram'
	import IconTwitter from '../../Icons/Twitter'
	import IconTiktok from '../../Icons/Tiktok'
	import IconYoutube from '../../Icons/Youtube'

	import LoaderUsersCreate from '../../PagesLoaders/UsersCreate'
	import { required, requiredIf } from 'vuelidate/lib/validators'

	export default {
		components: {
			IconArrowBack,
			IconClose,
			IconPeople,
			IconEdit,
			IconTrash,
			IconUpload,
			IconBuilding,
			IconEmail,
			IconMobile,
			IconCalendar,
			IconRole,
			IconDepartment,
			IconWorkSchedule,
			IconGlobe,
			IconSkill,
			IconLevel,
			IconFile,
			IconGears,
			LoaderUsersCreate,
			IconFacebook,
			IconLinkedin,
			IconInstagram,
			IconTwitter,
			IconTiktok,
			IconYoutube,
			IconVacantJob,
			IconQuestion
		},
		data() {
			return {
				loaded: false,
				canChangeDummy: true,
				dummyEmail: '',
				user: {
					avatar: '',
					first_name: '',
					last_name: '',
					email: '',
					phone: '',
					date_of_birth: {},
					married: 0,
					has_children: 0,
					date_of_employment: {},
					vacation_days: '',
					dummy_account: 0
				},
				social_links: {
					facebook_link: '',
					linkedin_link: '',
					instagram_link: '',
					twitter_link: '',
					tiktok_link: '',
					youtube_link: ''
				},
				business: {
					email: '',
					phone: ''
				},
			  	optionsRoles: [],
			  	selectedRoles: [],
			  	optionsWorkstations: [],
			  	selectedWorkstation: '',
			  	optionsDepartments: [],
			  	selectedDepartments: [],
			  	optionsSkills: [],
				selectedSkill: '',
			  	savedSkills: [],
				optionsSkillsLevels: [{name: 'Professional' , key: 'professional'},{name: 'Medium' , key: 'medium'},{name: 'Beginner' , key: 'beginner'}],
				selectedSkillLevel: '',
				optionsLanguages: [],
				selectedLanguage: '',
			  	savedLanguages: [],
				selectedLevelListening: '',
				selectedLevelReading: '',
				selectedLevelSpInt: '',
				selectedLevelSpPro: '',
				selectedLevelWriting: '',
				optionsListening: [
					{value: 'A1', text: this.$t('create-user.listening-a1')},
					{value: 'A2', text: this.$t('create-user.listening-a2')},
					{value: 'B1', text: this.$t('create-user.listening-b1')},
					{value: 'B2', text: this.$t('create-user.listening-b2')},
					{value: 'C1', text: this.$t('create-user.listening-c1')},
					{value: 'C2', text: this.$t('create-user.listening-c2')}
				],
				optionsReading: [
					{value: 'A1', text: this.$t('create-user.reading-a1')},
					{value: 'A2',text: this.$t('create-user.reading-a2')},
					{value: 'B1',text: this.$t('create-user.reading-b1')},
					{value: 'B2',text: this.$t('create-user.reading-b2')},
					{value: 'C1',text: this.$t('create-user.reading-c1')},
					{value: 'C2',text: this.$t('create-user.reading-c2')}
				],
				optionsSpokenInteraction: [
					{value: 'A1',text: this.$t('create-user.spoke-interaction-a1')},
					{value: 'A2',text: this.$t('create-user.spoke-interaction-a2')},
					{value: 'B1',text: this.$t('create-user.spoke-interaction-b1')},
					{value: 'B2',text: this.$t('create-user.spoke-interaction-b2')},
					{value: 'C1',text: this.$t('create-user.spoke-interaction-c1')},
					{value: 'C2',text: this.$t('create-user.spoke-interaction-c2')}
				],
				optionsSpokenProduction: [
					{value: 'A1',text: this.$t('create-user.spoke-interaction-a1')},
					{value: 'A2',text: this.$t('create-user.spoke-interaction-a2')},
					{value: 'B1',text: this.$t('create-user.spoke-interaction-b1')},
					{value: 'B2',text: this.$t('create-user.spoke-interaction-b2')},
					{value: 'C1',text: this.$t('create-user.spoke-interaction-c1')},
					{value: 'C2',text: this.$t('create-user.spoke-interaction-c2')}
				],
				optionsWriting: [
					{value: 'A1',text: this.$t('create-user.writing-a1')},
					{value: 'A2',text: this.$t('create-user.writing-a2')},
					{value: 'B1',text: this.$t('create-user.writing-b1')},
					{value: 'B2',text: this.$t('create-user.writing-b2')},
					{value: 'C1',text: this.$t('create-user.writing-c1')},
					{value: 'C2',text: this.$t('create-user.writing-c2')}
				],
				inputHobby: '',
				savedHobbies: [],
				fileUpload: '',
				fileExpire: false,
				fileDateExpire: {},
				fileExpireUsers: [],
				optionsUsers: [],
				updateFile: false,
				savedFiles: [],
				awaitingFilesForRemove: [],
				urlAvatar: '',
				optionsWorkSchedules: [],
				selectedWorkSchedule: '',
				customSchedule: {
					monday: {start: '', end: ''},
					tuesday: {start: '', end: ''},
					wednesday: {start: '', end: ''},
					thursday: {start: '', end: ''},
					friday: {start: '', end: ''},
					saturday: {start: '', end: ''},
					sunday: {start: '', end: ''}
				},
				timepickerKey: 1,
				calendarConfigs: {
					dateFormat: 'yyyy-mm-dd',
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true
				},
			  	optionsHobbies: [],
				error_message: '',
				selectedRight: '',
				optionsRights: [],
				error_email: false,
				error_business_email: false,
				optionsTimezones: [],
				selectedTimezone: '',
				fileError: '',
				optionsVacantJobs: [],
				selectedVacantJob: '',
				errorsBe: {},
				helpData: this.$store.getters['help_links/currentLink'](this.$route.name)
			};
		},
		async mounted(){
			await this.getFilters()

			if(this.$route.params.slug){
				await this.getUserData()
			}

			if(this.$route.query.department_id){
				this.selectedDepartments.push(this.optionsDepartments.find(el => el.id == this.$route.query.department_id))
			}

			if(this.$route.query.vacant_job && this.optionsVacantJobs.find(el => el.slug == this.$route.query.vacant_job)){
				this.selectedVacantJob = this.optionsVacantJobs.find(el => el.slug == this.$route.query.vacant_job)
				this.populateDataByVacantJob(this.selectedVacantJob)
			}

			this.$root.$on('addDropdownUserCreate', (data, type) => {
				switch (type) {
					case 'role':
						this.optionsRoles.push(data)
						this.selectedRoles.push(data)
						break;
					case 'workstation':
						this.optionsWorkstations.push(data)
						this.selectedWorkstation = data
						break;
					case 'department':
						this.optionsDepartments.push(data)
						this.selectedDepartments.push(data)
						break;
					case 'skill':
						this.optionsSkills.push(data.department)
						var skillObj = {
							level: data.level.key,
							id: data.department.id,
							name: data.department.name
						}

						this.savedSkills.push(skillObj)
						this.optionsSkills.find(el => el.id == data.department.id).$isDisabled = true
						break;
				}
			})

			await this.getRights()
		},
		validations: {
			user: {
				first_name: {required},
				last_name: {required},
				email: { required: requiredIf( function (model) {
					return this.user.dummy_account == 0
				})}
			},
			selectedRight: {required},
			selectedSkillLevel: {required: requiredIf(function (model) {
				return this.selectedSkill != ''
			})},
			customSchedule: {
				monday: {
					start: { required: requiredIf(function (model) {
						return this.customSchedule.monday.end
					})},
					end: { required: requiredIf(function (model) {
						return this.customSchedule.monday.start
					})}
				},
				tuesday: {
					start: { required: requiredIf(function (model) {
						return this.customSchedule.tuesday.end
					})},
					end: { required: requiredIf(function (model) {
						return this.customSchedule.tuesday.start
					})}
				},
				wednesday: {
					start: { required: requiredIf(function (model) {
						return this.customSchedule.wednesday.end
					})},
					end: { required: requiredIf(function (model) {
						return this.customSchedule.wednesday.start
					})}
				},
				thursday: {
					start: { required: requiredIf(function (model) {
						return this.customSchedule.thursday.end
					})},
					end: { required: requiredIf(function (model) {
						return this.customSchedule.thursday.start
					})}
				},
				friday: {
					start: { required: requiredIf(function (model) {
						return this.customSchedule.friday.end
					})},
					end: { required: requiredIf(function (model) {
						return this.customSchedule.friday.start
					})}
				},
				saturday: {
					start: { required: requiredIf(function (model) {
						return this.customSchedule.saturday.end
					})},
					end: { required: requiredIf(function (model) {
						return this.customSchedule.saturday.start
					})}
				},
				sunday: {
					start: { required: requiredIf(function (model) {
						return this.customSchedule.sunday.end
					})},
					end: { required: requiredIf(function (model) {
						return this.customSchedule.sunday.start
					})}
				}
			},
			fileExpireUsers: { required: requiredIf(function (model) {
				return this.fileExpire
			})},
			fileDateExpire: { 
				selectedDate: { required: requiredIf(function (model) {
					return this.fileExpire
				})}
			},
		},
		methods: {
			showModal(type, data = false){
				this.$root.$emit('open_modal', type, data);
			},
			async getRights() {
				this.optionsRights = ['admin','employee']

				this.optionsRights.map( el => {
					if(el.name == 'admin'){
						el.$isDisabled = this.$auth.user().is_entrepreneur ? false : true
					}
				})
			},
			async getFilters(){
				var filtersDB = {
					roles: true, 
					workstations: true, 
					work_schedules: this.$route.params.slug ? this.$route.params.slug : 'roles',
					foreign_languages: true, 
					skills: true, 
					departments: true, 
					hobbies: true,
					timezones: true,
					users: true,
					vacant_jobs: true
				}

				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: filtersDB })
				.then(({data}) => {
					this.optionsDepartments = data.data.departments
					this.optionsRoles = data.data.roles
        			this.optionsWorkstations = data.data.workstations
        			this.optionsWorkSchedules = data.data.work_schedules
					this.optionsWorkSchedules.push({name: 'Custom'})
        			this.optionsSkills = data.data.skills
        			this.optionsLanguages = data.data.foreign_languages
        			this.optionsHobbies = data.data.hobbies
        			this.optionsUsers = data.data.users
        			this.optionsVacantJobs = data.data.vacant_jobs

        			if(data.data.timezones){
	        			for (var key of Object.keys(data.data.timezones)){
							this.optionsTimezones.push({value: key, name: data.data.timezones[key]})
						}
        			}

					// this.optionsWorkSchedules.map(el => { el.$isDisabled = el.hasOwnProperty('role_id') &&  el.role_id != '' ? true : false })
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					if(!this.$route.params.slug){
						setTimeout(() => {
							this.loaded = true
							setTimeout(() => {
								$('.opacity-page').addClass('show')
							}, 0)
						}, 0)
					}
				})
			},
			async getUserData(){
				await axios.get(`/users/${this.$route.params.slug}/edit`)
				.then(({data}) => {
					let userData = data.data
					this.user = {
						id: userData.id,
						slug: userData.slug,
						first_name: userData.first_name,
						last_name: userData.last_name,
						email: userData.dummy_account ? '' : userData.email,
						phone: userData.phone ? userData.phone : '',
						married: userData.married,
						has_children: userData.has_children,
						vacation_days: userData.vacation_days ? userData.vacation_days : '',
						date_of_birth: {},
						date_of_employment: {},
						right: userData.right,
						dummy_account: userData.dummy_account
					}

					this.canChangeDummy = userData.dummy_account ? true : false
					this.dummyEmail = userData.email

					this.social_links = {
						facebook_link: userData.facebook_link ? userData.facebook_link : '',
						linkedin_link: userData.linkedin_link ? userData.linkedin_link : '',
						instagram_link: userData.instagram_link ? userData.instagram_link : '',
						twitter_link: userData.twitter_link ? userData.twitter_link : '',
						tiktok_link: userData.tiktok_link ? userData.tiktok_link : '',
						youtube_link: userData.youtube_link ? userData.youtube_link : ''
					}

					this.urlAvatar = userData.avatar ? userData.avatar : ''

					if(userData.date_of_birth){
						this.user.date_of_birth = {
							selectedDate: userData.date_of_birth,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}

					if(userData.date_of_employment){
						this.user.date_of_employment = {
							selectedDate: userData.date_of_employment,
							multipleDateRange: [],
							selectedDates: [],
							selectedDateTime: false,
							selectedDatesItem: "",
							selectedHour: "00",
							selectedMinute: "00",
							dateRange: {end: '',start: ''}
						}
					}

					this.business = {
						email: userData.business_email ? userData.business_email : '',
						phone: userData.business_phone ? userData.business_phone : ''
					}
					this.selectedWorkstation = userData.workstation_id ? this.optionsWorkstations.find(el => el.id == userData.workstation_id) : ''
					this.selectedWorkSchedule = userData.work_schedule_id ? this.optionsWorkSchedules.find(el => el.id == userData.work_schedule_id) : ''
					this.selectedTimezone = userData.timezone ? this.optionsTimezones.find(el => el.value == userData.timezone) : ''
					this.savedSkills = userData.skills

					if(userData.files.length){
						userData.files.map((el) => {
							this.savedFiles.push({name: el.filename, id: el.id, expire_date: el.expire_date, emails: el.emails})
						})
					}

					this.savedSkills.map((skill) => {
						this.optionsSkills.find(el => el.id == skill.id).$isDisabled = true
					})

					userData.roles.map((role) => {
						this.selectedRoles.push(this.optionsRoles.find(el => el.id == role))
					})

					userData.departments.map((department) => {
						this.selectedDepartments.push(this.optionsDepartments.find(el => el.id == department))
					})

					if(userData.hobbies.length){
						userData.hobbies.map((hobby) => {
							this.savedHobbies.push(hobby)
						})
					}

					if(Object.keys(userData.foreign_languages).length){
						Object.keys(userData.foreign_languages).map(langId => {
							var language = this.optionsLanguages.find(el => el.id == langId)
							language.$isDisabled = true
							this.savedLanguages.push({
								id: language.id,
								name: language.name,
								levels: userData.foreign_languages[langId]
							})
						})						
					}

					if(this.user.right && this.user.right == 'master_admin'){
						this.optionsRights.push('master_admin')
						this.selectedRight = 'master_admin'
					}else{
						this.selectedRight = this.user.right
					}
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
			},
			saveAction(type){
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';
				this.error_email = false,
				this.error_business_email = false

				this.$v.$touch()
				if(!this.$v.$invalid){
					var selectedRoles = this.selectedRoles.length ? this.selectedRoles.map(function(a) {return a.id;}) : []
					var selectedDepartments = this.selectedDepartments.length ? this.selectedDepartments.map(function(a) {return a.id;}) : ''
					var selectedWorkScheduleObject = this.selectedWorkSchedule ? (this.selectedWorkSchedule.name == 'Custom' ? this.generateSchedule() : {id: this.selectedWorkSchedule.id} ) : {}

					var selectedSkills = [...this.savedSkills]
					if(this.selectedSkill != ''){
						var skillObj = {
							level: this.selectedSkillLevel ? this.selectedSkillLevel.key : 'undefined',
							id: this.selectedSkill.id,
							name: this.selectedSkill.name
						}
					}

					var languages = [...this.savedLanguages]
					if(this.selectedLanguage != ''){
						languages.push({
							id: this.selectedLanguage.id,
							name: this.selectedLanguage.name,
							levels: [
								{type: 'listening', level: this.selectedLevelListening ? this.selectedLevelListening.value : 'undefined'},
								{type: 'reading', level: this.selectedLevelReading ? this.selectedLevelReading.value : 'undefined'},
								{type: 'spoke_interaction', level: this.selectedLevelSpInt ? this.selectedLevelSpInt.value : 'undefined'},
								{type: 'spoke_production', level: this.selectedLevelSpPro ? this.selectedLevelSpPro.value : 'undefined'},
								{type: 'writing', level: this.selectedLevelWriting ? this.selectedLevelWriting.value : 'undefined'}
							]
						})
					}

					var hobbies = [...this.savedHobbies] 
					if(this.savedHobbies.indexOf(this.inputHobby) === -1 && this.inputHobby != '') {
						hobbies.push(this.inputHobby);
					}
					
					this.addFile();

					let objData = {}

					if(Object.keys(this.user.date_of_employment).length){
						objData.date_of_employment = this.user.date_of_employment.selectedDate
					}
					if(Object.keys(this.user.date_of_birth).length){
						objData.date_of_birth = this.user.date_of_birth.selectedDate
					}

					objData.first_name = this.user.first_name
					objData.last_name = this.user.last_name
					objData.email = this.user.dummy_account ? this.dummyEmail : this.user.email
					objData.phone = this.user.phone
					objData.married = this.user.married !== '' ? this.user.married : ''
					objData.has_children = this.user.has_children !== '' ? this.user.has_children : ''
					objData.business_email = this.business.email
					objData.business_phone = this.business.phone
					objData.vacation_days = this.user.vacation_days
					objData.workstation_id = this.selectedWorkstation ? this.selectedWorkstation.id : ''
					objData.right = this.selectedRight;

					objData.facebook_link = this.social_links.facebook_link
					objData.linkedin_link = this.social_links.linkedin_link
					objData.instagram_link = this.social_links.instagram_link
					objData.twitter_link = this.social_links.twitter_link
					objData.tiktok_link = this.social_links.tiktok_link
					objData.youtube_link = this.social_links.youtube_link

					objData.timezone = this.selectedTimezone ? this.selectedTimezone.value : ''
					objData.roles = selectedRoles

					objData.dummy_account = this.user.dummy_account

					if(selectedDepartments.length) { objData.departments = selectedDepartments }
					if(Object.keys(selectedWorkScheduleObject).length) { objData.work_schedules = selectedWorkScheduleObject }
					if(selectedSkills.length) { objData.skills = selectedSkills }
					if(languages.length) { objData.foreign_languages = languages }
					if(hobbies.length) { objData.hobbies = hobbies }

					if(type == 'create'){
						this.createUser(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateUser(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createUser(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/users/store', objData)
				.then(({data}) => {
					this.storeFiles(type, data.data.id, btnSubmit, btnSubmitText, btnSubmitLoader)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							} else if(error.response.data.errors.email[0]) {
								this.error_message = error.response.data.errors.email[0]
								this.error_email = true;
							} else if(error.response.data.errors.business_email[0]) {
								this.error_message = error.response.data.errors.business_email[0]
								this.error_business_email = true;
							} 
						}, 1000)
					}, 300)
				})
			},
			updateUser(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/users/${this.user.slug}`, objData)
				.then(({data}) => {
					this.storeFiles(type, this.user.id, btnSubmit, btnSubmitText, btnSubmitLoader)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							} else if(error.response.data.errors.email[0]) {
								this.error_message = error.response.data.errors.email[0]
								this.error_email = true;
							} else if(error.response.data.errors.business_email[0]) {
								this.error_message = error.response.data.errors.business_email[0]
								this.error_business_email = true;
							} 
						}, 1000)
					}, 300)
				})
			},
			storeFiles(type, userId, btnSubmit, btnSubmitText, btnSubmitLoader){
				var data = new FormData();

				data.append('user_id', userId)
				if(this.user.avatar) { data.append('avatar', this.user.avatar) }

				for( var i = 0; i < this.savedFiles.length; i++ ){
					if(this.savedFiles[i]){
						let fileItem = this.savedFiles[i];
						
						if(fileItem.file){ data.append('files[' + i + '][file]', fileItem.file); }
						if(fileItem.id){ data.append('files[' + i + '][id]', fileItem.id); }
						if(fileItem.expire_date){
							data.append('files[' + i + '][expire_date]', fileItem.expire_date);
							data.append('files[' + i + '][emails]', fileItem.emails.join(","));
						}
					}
				}

				if(this.awaitingFilesForRemove.length){
					for( var i = 0; i < this.awaitingFilesForRemove.length; i++ ){
						let fileRemoveId = this.awaitingFilesForRemove[i];
						data.append('remove_files_ids[' + i + ']', fileRemoveId);
					}
				}

				axios.post('user-files/store', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }})
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							if(this.user.id == this.$auth.user().id){ this.$auth.fetch() }

							if(this.selectedVacantJob) {
								this.showModal('vacant_job_duration', { vacantJob: this.selectedVacantJob, userSlug: data.data.slug } )
							} else {
								if(this.$route.query.department_id){
									this.$router.push({name: 'company'})
								}else{
									this.$router.push({name: 'user-show', params: { slug: data.data.slug }})
								}
							}
						}, 500)
					}, 300)
				}).catch(error =>{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 413){
								this.error_message = this.$t('error.too_large')
							} else if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							} else if(error.response.data.errors.avatar) {
								this.error_message = error.response.data.errors.avatar[0];
							}
						}, 1000)
					}, 300)
				})
			},
			addOptionLanguage(){
				var languageObj = {
					id: this.selectedLanguage.id,
					name: this.selectedLanguage.name,
					levels: [
						{type: 'listening', level: this.selectedLevelListening ? this.selectedLevelListening.value : 'undefined'},
						{type: 'reading', level: this.selectedLevelReading ? this.selectedLevelReading.value : 'undefined'},
						{type: 'spoke_interaction', level: this.selectedLevelSpInt ? this.selectedLevelSpInt.value : 'undefined'},
						{type: 'spoke_production', level: this.selectedLevelSpPro ? this.selectedLevelSpPro.value : 'undefined'},
						{type: 'writing', level: this.selectedLevelWriting ? this.selectedLevelWriting.value : 'undefined'}
					]
				}

				this.savedLanguages.push(languageObj)

				this.optionsLanguages.find(el => el.id == this.selectedLanguage.id).$isDisabled = true

				this.selectedLanguage = ''
				this.clearLanguagesLevels()
			},
			clearLanguagesLevels() {
				this.selectedLevelListening = ''
				this.selectedLevelReading = ''
				this.selectedLevelSpInt = ''
				this.selectedLevelSpPro = ''
				this.selectedLevelWriting = ''
			},
			editLanguageLevel(obj){
				var language_selected = this.optionsLanguages.find(el => el.id == obj.id)
				language_selected.$isDisabled = false
				this.savedLanguages.splice(this.savedLanguages.findIndex((el) => el.id == obj.id), 1)

				this.selectedLanguage = language_selected

				var listening = obj.levels.find((el) => el.type == 'listening')
				this.selectedLevelListening = listening.level != 'undefined' ? this.optionsListening.find(el => el.value == listening.level) : ''
				
				var reading = obj.levels.find((el) => el.type == 'reading')
				this.selectedLevelReading = reading.level != 'undefined' ? this.optionsReading.find(el => el.value == reading.level) : ''
				
				var spoke_interaction = obj.levels.find((el) => el.type == 'spoke_interaction')
				this.selectedLevelSpInt = spoke_interaction.level != 'undefined' ? this.optionsSpokenInteraction.find(el => el.value == spoke_interaction.level) : ''
				
				var spoke_production = obj.levels.find((el) => el.type == 'spoke_production')
				this.selectedLevelSpPro = spoke_production.level != 'undefined' ? this.optionsSpokenProduction.find(el => el.value == spoke_production.level) : ''
				
				var writing = obj.levels.find((el) => el.type == 'writing')
				this.selectedLevelWriting = writing.level != 'undefined' ? this.optionsWriting.find(el => el.value == writing.level) : ''
			},
			deleteLanguageLevel(language){
				this.optionsLanguages.find(el => el.id == language.id).$isDisabled = false
				this.savedLanguages.splice(this.savedLanguages.findIndex((el) => el.id == language.id), 1)
			},
			addSkill(){
				this.$v.selectedSkillLevel.$touch()
				if (!this.$v.selectedSkillLevel.$invalid) {
					var skillObj = {
						level: this.selectedSkillLevel ? this.selectedSkillLevel.key : 'undefined',
						id: this.selectedSkill.id,
						name: this.selectedSkill.name
					}

					this.savedSkills.push(skillObj)

					this.optionsSkills.find(el => el.id == this.selectedSkill.id).$isDisabled = true

					this.selectedSkill = ''
					this.selectedSkillLevel = ''
					this.$v.selectedSkillLevel.$reset()
				}
			},
			editSkillLevel(obj){
				var skill_selected = this.optionsSkills.find(el => el.id == obj.id)
				skill_selected.$isDisabled = false
				this.savedSkills.splice(this.savedSkills.findIndex((el) => el.id == obj.id), 1)

				this.selectedSkill = skill_selected
				this.selectedSkillLevel = obj.level != 'undefined' ? this.optionsSkillsLevels.find(el => el.key == obj.level) : ''
			},
			deleteSkillLevel(skill){
				if(this.optionsSkills.find(el => el.id == skill.id)){
					this.optionsSkills.find(el => el.id == skill.id).$isDisabled = false
				}
				this.savedSkills.splice(this.savedSkills.findIndex((el) => el.skill == skill), 1)
			},
			addHobby(){
				if(this.savedHobbies.indexOf(this.inputHobby) === -1 && this.inputHobby != '') {
					this.savedHobbies.push(this.inputHobby);
				}
				this.inputHobby = ''
			},
			deleteHobby(index){
				this.savedHobbies.splice(index,1);
			},
			onFileChange(e) {
				this.fileError = ''

				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;

				this.fileUpload = files[0]
			},
			addFile(){
				this.$v.fileDateExpire.$touch()
				this.$v.fileExpireUsers.$touch()

				if(!this.$v.fileDateExpire.$error && !this.$v.fileExpireUsers.$error){
					if(this.fileUpload){
						if(this.fileUpload.size <= 24999999){
							let objectFile = {
								file: this.fileUpload,
								name: this.fileUpload.name,
								expire_date: this.fileExpire ? this.fileDateExpire.selectedDate : "",
								emails: this.fileExpire ? this.fileExpireUsers.map(function(a) {return a.email;}) : ""
							}

							this.savedFiles.push(objectFile)
							this.clearFile()
						}else {
							this.fileError = this.$t('validator.file_size')
						}
					}
				}
			},
			editFile(fileItem, index){
				this.updateFile = index
				this.fileUpload = { name: fileItem.name }
				if(fileItem.expire_date){
					this.fileExpire = true
					this.fileDateExpire = {
						selectedDate: fileItem.expire_date,
						multipleDateRange: [],
						selectedDates: [],
						selectedDateTime: false,
						selectedDatesItem: "",
						selectedHour: "00",
						selectedMinute: "00",
						dateRange: {end: '',start: ''}
					}
					fileItem.emails.map((email) => {
						this.fileExpireUsers.push(this.optionsUsers.find(el => el.email == email))
					})
				}
			},
			cancelEdit(){
				this.updateFile = false
				this.clearFile()
			},
			updateFileFunction(){
				if(this.fileUpload.size){
					if(this.fileUpload.size <= 24999999){
						this.savedFiles[this.updateFile].file = this.fileUpload;
						this.savedFiles[this.updateFile].name = this.fileUpload.name;
						this.savedFiles[this.updateFile].expire_date = this.fileExpire ? this.fileDateExpire.selectedDate : "";
						this.savedFiles[this.updateFile].emails = this.fileExpire ? this.fileExpireUsers.map(function(a) {return a.email;}) : "";
					}else{
						this.fileError = this.$t('validator.file_size')
					}
				}else{
					this.savedFiles[this.updateFile].name = this.fileUpload.name;
					this.savedFiles[this.updateFile].expire_date = this.fileExpire ? this.fileDateExpire.selectedDate : "";
					this.savedFiles[this.updateFile].emails = this.fileExpire ? this.fileExpireUsers.map(function(a) {return a.email;}) : "";
				}

				this.cancelEdit()
			},
			clearFile(){
				document.getElementById("otherFile").value = "";
				this.fileUpload = ''
				this.fileExpire = false
				this.fileDateExpire = {}
				this.fileExpireUsers = []
			},
			deleteFile(file, index){
				if(file.id){
					this.awaitingFilesForRemove.push(file.id)
				}
				this.savedFiles.splice(index, 1)
			},
			onAvatarChange(e){
				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;

				this.urlAvatar = URL.createObjectURL(files[0]);
				this.user.avatar = files[0]
			},
			generateSchedule(){
				var schedules = []

				if(this.customSchedule.monday.start && this.customSchedule.monday.end){
					var mondayObj = {day: 'monday', start_time: this.customSchedule.monday.start, end_time: this.customSchedule.monday.end}
					schedules.push(mondayObj)
				}
				if(this.customSchedule.tuesday.start && this.customSchedule.tuesday.end){
					var tuesdayObj = {day: 'tuesday', start_time: this.customSchedule.tuesday.start, end_time: this.customSchedule.tuesday.end}
					schedules.push(tuesdayObj)
				}
				if(this.customSchedule.wednesday.start && this.customSchedule.wednesday.end){
					var wednesdayObj = {day: 'wednesday', start_time: this.customSchedule.wednesday.start, end_time: this.customSchedule.wednesday.end}
					schedules.push(wednesdayObj)
				}
				if(this.customSchedule.thursday.start && this.customSchedule.thursday.end){
					var thursdayObj = {day: 'thursday', start_time: this.customSchedule.thursday.start, end_time: this.customSchedule.thursday.end}
					schedules.push(thursdayObj)
				}
				if(this.customSchedule.friday.start && this.customSchedule.friday.end){
					var fridayObj = {day: 'friday', start_time: this.customSchedule.friday.start, end_time: this.customSchedule.friday.end}
					schedules.push(fridayObj)
				}
				if(this.customSchedule.saturday.start && this.customSchedule.saturday.end){
					var saturdayObj = {day: 'saturday', start_time: this.customSchedule.saturday.start, end_time: this.customSchedule.saturday.end}
					schedules.push(saturdayObj)
				}
				if(this.customSchedule.sunday.start && this.customSchedule.sunday.end){
					var sundayObj = {day: 'sunday', start_time: this.customSchedule.sunday.start, end_time: this.customSchedule.sunday.end}
					schedules.push(sundayObj)
				}

				var objCustomSchedule = {
					name: `Custom ${this.user.first_name} ${this.user.last_name}`,
					schedules: schedules
				}

				return objCustomSchedule
			},
			addNewHobby(newHobby){
				this.optionsHobbies.push(newHobby)
				this.inputHobby = newHobby
			},
			populateDataByVacantJob(value, id) {
				if(value){
					axios.get(`/vacant-jobs/${value.slug}/show`)
					.then(({data}) => {
						let vacantJobData = data.data

						this.selectedWorkstation = vacantJobData.workstation ? this.optionsWorkstations.find(el => el.id == vacantJobData.workstation.id) : ''
						this.savedSkills = vacantJobData.skills
						this.savedSkills.map((skill) => {
							this.optionsSkills.find(el => el.id == skill.id).$isDisabled = true
						})

						this.selectedRoles = []
						vacantJobData.roles.map((role) => {
							this.selectedRoles.push(this.optionsRoles.find(el => el.id == role.id))
						})

						this.selectedDepartments = []
						vacantJobData.departments.map((department) => {
							this.selectedDepartments.push(this.optionsDepartments.find(el => el.id == department.id))
						})

						this.savedLanguages = []
						if(vacantJobData.foreign_languages.length){
							vacantJobData.foreign_languages.map(lang => {
								var language = this.optionsLanguages.find(el => el.id == lang.id)
								language.$isDisabled = true
								this.savedLanguages.push({
									id: language.id,
									name: language.name,
									levels: lang.levels
								})
							})						
						}

						if(vacantJobData.work_schedule) {
							if(this.optionsWorkSchedules.find(el => el.id == vacantJobData.work_schedule.id)){
								this.selectedWorkSchedule = this.optionsWorkSchedules.find(el => el.id == vacantJobData.work_schedule.id)
							} else {
								this.selectedWorkSchedule = this.optionsWorkSchedules.find(el => el.name == 'Custom')

								vacantJobData.work_schedule.schedules.map(el => {
									this.customSchedule[el.day] = { start: el.start_time, end: el.end_time }
								})
							}
						}
					})
				} else {
					this.selectedRoles = []
					this.selectedDepartments = []
					this.savedLanguages = []
					this.savedSkills = []
					this.selectedWorkSchedule = ''
					this.selectedWorkstation = ''
					this.customSchedule = {
						monday: {start: '', end: ''},
						tuesday: {start: '', end: ''},
						wednesday: {start: '', end: ''},
						thursday: {start: '', end: ''},
						friday: {start: '', end: ''},
						saturday: {start: '', end: ''},
						sunday: {start: '', end: ''}
					}
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.custom-file-error{
		color: #FB5454;
		font-size: 12px;
		width: 100%;
		text-align: right;
		margin-top: 10px;
	}
</style>